import '../styles/globals.scss';
import '../styles/ltm.scss';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Live To Mobile | Conference app"
        titleTemplate="%s"
        defaultTitle="Live To Mobile | Conference app"
        description="Live To Mobile is a modern, scalable and real-time audio and/or video experience for your live streams."
        twitter={{
          handle: '@livetomobile',
          site: '@livetomobile',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          url: 'https://livetomobile.com',
          images: [
            {
              url: 'https://livetomobile.com/images/livetomobile-open-graph.png',
              width: 2000,
              height: 1000,
              type: 'image/png',
            },
          ],
          site_name: 'Live To Mobile',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/livetomobile-apple-touch.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/livetomobile-safari-pinned-tab.svg',
            color: '#070707',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
